import React, { useEffect, useState } from 'react';
import "./admin.css";
import menuIcon from "../../../assets/imgs/navbar/menu-open.svg";
import menuIconClicked from "../../../assets/imgs/navbar/menu-closed.svg";
import dogImg from "../../../assets/imgs/prepare-shipment/restrictions/dog.png";
import { AiFillCaretDown, AiFillContacts } from 'react-icons/ai';
import { FaUserCheck, FaUserShield, FaPen, FaFileInvoiceDollar, FaSignOutAlt,FaReceipt, FaBook, FaDesktop } from "react-icons/fa";
import { FaRectangleList, FaClock } from "react-icons/fa6";
import { MdLocalAirport } from "react-icons/md";
import { BsFillPostcardFill } from "react-icons/bs";
import { TbTruckDelivery } from "react-icons/tb";
import MainBanner from '../../../components/dashboard/main-banner/main-banner';
import Usuarios from './usuarios/usuarios';
import Permisos from './permisos/permisos';
import Banners from './banners/banners';
import Textos from './textos/textos';
import Contactos from './contactos/contactos';
import Tarifario from './tarifario/tarifario';
import Bienvenido from './bienvenido/bienvenido';
import Cotizaciones from './cotizaciones/cotizaciones';
import PostalCodes from './codigos-postales/codigos-postales';
import Stations from './stations/stations';
import Service from './services/service';
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import Loading from '../components/loading/loading';
import UserNotFound from '../components/user-not-found/user-not-found';
import SessionExpired from '../../../components/session-expired/session-expired';
import PdfLogger from './pdf-logger/pdf-logger';
import InternationalStations from './internationalStations/international-stations';
import ShiftChange from './shift-change/shift-change';
import LoginReports from './login-reports/login-reports';
import KpiPersonal from './kpi-personal/kpi-personal';
import Shifts from './shifts/shifts';
import Counters from './counters/counters';

function Admin() {
    const {user, isAuthenticated, isLoading, logout,} = useAuth0();
    const [hasFetchedData, setHasFetchedData] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [imgUser, setImgUser] = useState(dogImg);
    const [stations, setStations] = useState([])
    
    useEffect(() => {
        const emailRegularExpression = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/;
        if(isAuthenticated && !hasFetchedData) {

            let email = user.sub.match(emailRegularExpression);

            if(email === null) {
                email = ['user', user.email]
            }

            setImgUser(user.picture)
            const data = {
                "email": email[1],
                'key': process.env.REACT_APP_KEY
            }
            axios.post(`${process.env.REACT_APP_URL_BACKEND}/api/user/login`, data)
            .then(response => {
                localStorage.setItem('token', response.data.user.token);
                localStorage.setItem('permissions', response.data.permissions);
            })
            .catch(error => {
                console.error('error: ',error);
                setShowAlert(true);
            })
            .finally(() => {
                setHasFetchedData(true)
            })
                
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, hasFetchedData])

    const sectionNames = {
        Usuarios: 'Usuarios',
        Permisos: 'Permisos',
        Banners: 'Banners',
        Textos: 'Textos',
        Contactos: 'Contactos Web',
        Tarifario: 'Tarifario nacional',
        Cotizaciones: 'Cotizaciones',
        PostalCodes: 'Codigos Postales',
        Estaciones: 'Estaciones',
        Servicios: 'Servicios',
        PdfLogger: 'PDF Logger',
        InternationalStations: 'Estaciones Internacionales',
        ShiftChange: "Cambio de turnos",
        LoginReports: "Reportes de Login",
        KpiPersonal: "Kpi's Personal",
        Shifts: "Turnos",
        Counters: "Mostradores"
    };

    const getSectionIcon = (section) => {
        switch (section) {
            case 'Usuarios':
                return <FaUserCheck className="fs-5 align-self-center" />;
            case 'Permisos':
                return <FaUserShield className="fs-5 align-self-center" />;
            case 'Banners':
                return <BsFillPostcardFill className="fs-5 align-self-center" />;
            case 'Textos':
                return <FaPen className="fs-5 align-self-center" />;
            case 'Contactos':
                return <AiFillContacts className="fs-5 align-self-center" />;
            case 'Tarifario':
                return <FaFileInvoiceDollar className="fs-5 align-self-center" />;
            case 'Cotizaciones':
                return <FaReceipt className="fs-5 align-self-center" />;
            case 'PostalCodes':
                return <TbTruckDelivery className="fs-5 align-self-center" />;
            case 'Estaciones':
                return <MdLocalAirport className="fs-5 align-self-center" />;
            case 'Servicios':
                return <FaRectangleList className="fs-5 align-self-center" />;
            case 'PdfLogger':
                return <FaBook className="fs-5 align-self-center" />;
            case 'InternationalStations':
                return <MdLocalAirport className="fs-5 align-self-center" />;
            case 'ShiftChange':
                return <FaClock className="fs-5 align-self-center" />
            case 'LoginReports':
                return <FaBook className="fs-5 align-self-center" />
            case 'KpiPersonal':
                return <FaBook className="fs-5 align-self-center" />
            case 'Shifts':
                return <FaRectangleList className="fs-5 align-self-center" />
            case 'Counters':
                return <FaDesktop className="fs-5 align-self-center" />
            default:
                return null;
        }
    };
    
    const getStations = async () => {
        try {
          const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/stations`, {
            headers: {
              authorization: process.env.REACT_APP_PUBLIC_KEY,
            },
          });
          if (res.data.code === 1) {
            const data = res.data.stations.filter((station) => station.add_quote === 1)
            setStations(data);
          }
        } catch (error) {
          console.error(error);
        }
    }
    
    useEffect(() => {
        getStations()
    }, [])
    
    if (stations.length !== 0) {
        localStorage.setItem('stations', JSON.stringify(stations));
    }
    
    const [selectedComponent, setSelectedComponent] = useState('');
    const handleMenuClick = (componentName) => {
        setSelectedComponent(componentName);
        closeMobileMenu();
    };
    
    const closeMobileMenu = () => setClick(false);
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    const logoutAdmin = () => {
        localStorage.clear();
        logout({logoutParams: { returnTo: process.env.REACT_APP_LOGOUT_URL}});
    }

    if (!isAuthenticated && !isLoading) {
        return (
            <>
                <SessionExpired />
            </>
        );
    }
 
    if (isLoading || !hasFetchedData) {
        return <Loading />
    }

  return (
    <div className="container-fluid p-0 m-0">
        {!showAlert ? (
            <>
            <MainBanner />
            <div className="d-flex">
                <div className="admin-menu">
                    <div className="menu-icon-open-close" onClick={handleClick}>
                        <img src={click ? menuIconClicked : menuIcon} alt={click ? 'Closed Menu' : 'Open Menu'} />
                    </div>
                    <div className={click ? 'menu-admin active' : 'menu-admin'}>
                        <div className="d-flex flex-column">
                            <div className=" d-flex justify-content-center pb-2">
                                <img className="admin-profile-img" src={imgUser} alt=""/>
                            </div>
                            <div className="d-flex justify-content-center pb-1">
                                <button className="admin-profile-btn">Nombre<AiFillCaretDown className="ps-2 m-0 fs-5"/></button>
                            </div>
                            <div className="d-flex justify-content-center admin-profile-role pb-2 pb-md-0">
                                <p>{user.nickname}</p>
                            </div>
                        </div>
                        {localStorage.getItem('permissions').includes('1') && (
                            <div className="menu-admin-item">
                                <div className="text-menu-item" onClick={() => handleMenuClick('Usuarios')}>
                                    <FaUserCheck className="fs-5"/>
                                    <p className="ps-2 m-0">Usuarios</p>
                                </div>
                            </div>
                        )}
                        {localStorage.getItem('permissions').includes('2') && (
                            <div className="menu-admin-item">
                                <div className="text-menu-item" onClick={() => handleMenuClick('Permisos')}>
                                    <FaUserShield className="fs-5"/>
                                    <p className="ps-2 m-0">Permisos</p>
                                </div>
                            </div>
                        )}
                        {localStorage.getItem('permissions').includes('3') && (
                            <div className="menu-admin-item">
                                <div className="text-menu-item" onClick={() => handleMenuClick('Banners')}>
                                <BsFillPostcardFill className="fs-5"/>
                                    <p className="ps-2 m-0">Banners</p>
                                </div>
                            </div>
                        )}
                        {localStorage.getItem('permissions').includes('4') && (
                            <div className="menu-admin-item">
                                <div className="text-menu-item" onClick={() => handleMenuClick('Textos')}>
                                    <FaPen className="fs-5"/>
                                    <p className="ps-2 m-0">Textos</p>
                                </div>
                            </div>
                        )}
                        {localStorage.getItem('permissions').includes('5') && (
                            <div className="menu-admin-item">
                                <div className="text-menu-item" onClick={() => handleMenuClick('Contactos')}>
                                    <AiFillContacts className="fs-5"/>
                                    <p className="ps-2 m-0">Contactos Web</p>
                                </div>
                            </div>
                        )}
                        {localStorage.getItem('permissions').includes('6') && (
                            <div className="menu-admin-item">
                                <div className="text-menu-item" onClick={() => handleMenuClick('Tarifario')}>
                                    <FaFileInvoiceDollar className="fs-5"/>
                                    <p className="ps-2 m-0">Tarifario nacional</p>
                                </div>
                            </div>
                        )}
                        {localStorage.getItem('permissions').includes('7') && (
                            <div className="menu-admin-item">
                                <div className="text-menu-item" onClick={() => handleMenuClick('Cotizaciones')}>
                                    <FaReceipt  className="fs-5"/>
                                    <p className="ps-2 m-0">Cotizaciones</p>
                                </div>
                            </div>
                        )}
                        {localStorage.getItem('permissions').includes('8') && (
                            <div className="menu-admin-item">
                                <div className="text-menu-item" onClick={() => handleMenuClick('PostalCodes')}>
                                    <TbTruckDelivery  className="fs-5"/>
                                    <p className="ps-2 m-0">Codigos Postales</p>
                                </div>
                            </div>
                        )}
                        {localStorage.getItem('permissions').includes('9') && (
                            <div className="menu-admin-item">
                                <div className="text-menu-item" onClick={() => handleMenuClick('Estaciones')}>
                                    <MdLocalAirport  className="fs-5"/>
                                    <p className="ps-2 m-0">Estaciones</p>
                                </div>
                            </div>
                        )}
                        {localStorage.getItem('permissions').includes('10') && (
                            <div className="menu-admin-item">
                                <div className="text-menu-item" onClick={() => handleMenuClick('Servicios')}>
                                    <FaRectangleList className="fs-5"/>
                                    <p className="ps-2 m-0">Servicios</p>
                                </div>
                            </div>
                        )}
                        {localStorage.getItem('permissions').includes('11') && (
                            <div className="menu-admin-item">
                                <div className="text-menu-item" onClick={() => handleMenuClick('PdfLogger')}>
                                    <FaBook className="fs-5"/>
                                    <p className="ps-2 m-0">PDF Logger</p>
                                </div>
                            </div>
                        )
                        }
                        {localStorage.getItem('permissions').includes('12') && (
                            <div className="menu-admin-item">
                                <div className="text-menu-item" onClick={() => handleMenuClick('InternationalStations')}>
                                    <MdLocalAirport className="fs-5"/>
                                    <p className="ps-2 m-0">Estaciones Int...</p>
                                </div>
                            </div>
                        )
                        }
                        {localStorage.getItem('permissions').includes('24') && (
                            <div className="menu-admin-item">
                                <div className="text-menu-item" onClick={() => handleMenuClick('ShiftChange')}>
                                    <FaClock className="fs-5"/>
                                    <p className="ps-2 m-0">Cambio de turno</p>
                                </div>
                            </div>
                        )
                        }
                        {localStorage.getItem('permissions').includes('25') && (
                            <div className="menu-admin-item">
                                <div className="text-menu-item" onClick={() => handleMenuClick('LoginReports')}>
                                    <FaBook className="fs-5"/>
                                    <p className="ps-2 m-0">Reporte de Login</p>
                                </div>
                            </div>
                        )
                        }
                        {localStorage.getItem('permissions').includes('26') && (
                            <div className="menu-admin-item">
                                <div className="text-menu-item" onClick={() => handleMenuClick('KpiPersonal')}>
                                    <FaBook className="fs-5"/>
                                    <p className="ps-2 m-0">Kpi's Personal</p>
                                </div>
                            </div>
                        )
                        }
                        {localStorage.getItem('permissions').includes('27') && (
                            <div className="menu-admin-item">
                                <div className="text-menu-item" onClick={() => handleMenuClick('Shifts')}>
                                    <FaRectangleList className="fs-5"/>
                                    <p className="ps-2 m-0">Turnos</p>
                                </div>
                            </div>
                        )
                        }
                        {localStorage.getItem('permissions').includes('28') && (
                            <div className="menu-admin-item">
                                <div className="text-menu-item" onClick={() => handleMenuClick('Counters')}>
                                    <FaDesktop className="fs-5"/>
                                    <p className="ps-2 m-0">Mostradores</p>
                                </div>
                            </div>
                        )
                        }
                        <div className="menu-admin-item">
                            <div className="text-menu-item"onClick={logoutAdmin}>
                                <FaSignOutAlt className="fs-5"/>
                                <p className="ps-2 m-0">Cerrar sesión</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="menu-item-content">
                    <div className="main-panel">
                        <div className="main-panel-name d-flex">
                            {getSectionIcon(selectedComponent)}
                            <p className="fw-bold ps-2 m-0 align-self-center">{sectionNames[selectedComponent]}</p>
                        </div>
                        <div className="main-panel-body">
                            {selectedComponent === 'Usuarios' && <Usuarios />}
                            {selectedComponent === 'Permisos' && <Permisos />}
                            {selectedComponent === 'Banners' && <Banners />}
                            {selectedComponent === 'Textos' && <Textos />}
                            {selectedComponent === 'Contactos' && <Contactos />}
                            {selectedComponent === 'Tarifario' && <Tarifario />}
                            {selectedComponent === '' && <Bienvenido />}
                            {selectedComponent === 'Cotizaciones' && <Cotizaciones />}
                            {selectedComponent === 'PostalCodes' && <PostalCodes />}
                            {selectedComponent === 'Estaciones' && <Stations />}
                            {selectedComponent === 'Servicios' && <Service />}
                            {selectedComponent === 'PdfLogger' && <PdfLogger />}
                            {selectedComponent === 'InternationalStations' && <InternationalStations />}
                            {selectedComponent === 'ShiftChange' && <ShiftChange />}
                            {selectedComponent === 'LoginReports' && <LoginReports />}
                            {selectedComponent === 'KpiPersonal' && <KpiPersonal />}
                            {selectedComponent === 'Shifts' && <Shifts />}
                            {selectedComponent === 'Counters' && <Counters />}
                        </div>
                    </div>
                </div>
            </div>
            </>
            ) : 
            (
                <UserNotFound />
            )
        }

    </div>
  )
}

export default Admin