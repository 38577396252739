import { BehaviorSubject, Subject } from "rxjs";
let storedData = [];
let btnEmmit = false;
const formSubmitSubject = new BehaviorSubject(null);
const openConfirmationModal = new BehaviorSubject(null);
const formResetSubject = new Subject();
export const formService = {
  formSubmitSubject,
  triggerSubmit: () => formSubmitSubject.next(),
};
export const formResetService = {
  resetForm: () => formResetSubject.next(),
  onReset: () => formResetSubject.asObservable(),
}
export const removeLastData = () => {
  storedData.pop();
}
export const getData = () => {
  return storedData;
};
export const addData = (newData) => {
  storedData.push(newData);
}
export const getBtnEmmit = () => {
  return btnEmmit;
}
export const setBtnEmmit = () => {
  btnEmmit = true;
}
export const setConfirmationModal = (validation) => {
  openConfirmationModal.next(validation);
}
export const getConfirmationModal = () => {
  return openConfirmationModal.asObservable();
}