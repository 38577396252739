import React, { useState, useEffect } from "react";
import "./style.css";

import { Modal, Button, Table, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";

import HeavyHeader from "../../components/heavy-header/heavy-header";

const Card = ({ piezas, nombre, ubicacion, embarque, turno, handleShow }) => {
  return (
    <div className="turn-card" onClick={handleShow}>
      <h1>{ubicacion}</h1>
      <h3>{nombre}</h3>
      <p>No. Ticket</p>
      <p>{embarque}</p>
      <p>No. Tarimas</p>
      <span>{piezas}</span>
    </div>
  );
};

const Weigher = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedTurn, setSelectedTurn] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const getData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_HEAVY_LOAD}/weighing`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("heavy-load-token")}`,
          },
        }
      );
      setData(res.data.data.shipments);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleShow = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_HEAVY_LOAD}/weighing/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("heavy-load-token")}`,
          },
        }
      );
      if (res.data.code === 1) {
        setSelectedTurn(res.data.data.shipment);
        reset();
        setShowModal(true);
      }
    } catch (error) {
      console.error("Error fetching turn data", error);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedTurn(null);
    reset();
  };

  const handleConfirm = async (data) => {
    const body = {
      id: data.id,
      quantity_id: data.quantityID,
      user_id: localStorage.getItem("heavy-load-email"),
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_HEAVY_LOAD}/weighing/update`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("heavy-load-token")}`,
          },
        }
      );

      if (res.data.code === 1) {
        handleClose();
        getData();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="weigher-container">
        <HeavyHeader />
        <div className="weigher-body">
          <div className="weigher-body-title">
            <h1>Pesaje</h1>
          </div>
          <div className="weigher-body-turns">
            {loading ? (
              <div className="loading-spinner">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Cargando...</span>
                </Spinner>
              </div>
            ) : (
              <div className="weigher-body-turns-cards">
                {data.length > 0 ? (
                  data
                    .slice(0, 8)
                    .map((item, index) => (
                      <Card
                        key={index}
                        embarque={item.tag_number}
                        turno={item.turn_number}
                        piezas={item.number_pallets}
                        nombre={item.consignee}
                        ubicacion={item.destination}
                        handleShow={() => handleShow(item.id)}
                      />
                    ))
                ) : (
                  <div className="weigher-body-turns-no-found">
                    No hay registros
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {selectedTurn && (
        <Modal
          show={showModal}
          onHide={handleClose}
          centered
          dialogClassName="custom-modal-width"
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: "#020c41",
              color: "#FFFFFF",
              backgroundImage: "none",
              textAlign: "center",
            }}
          >
            <Modal.Title>Información</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit(handleConfirm)}>
            <Modal.Body>
              <Table bordered style={{ textAlign: "center" }}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#a3a3a3",
                        color: "#FFFFFF",
                        padding: "20px",
                      }}
                    >
                      # de turno
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        defaultValue={selectedTurn?.turn_number}
                        readOnly
                        style={{ border: "none" }}
                      />
                    </td>
                    <td
                      style={{
                        backgroundColor: "#a3a3a3",
                        color: "#FFFFFF",
                        padding: "5px",
                      }}
                    >
                      # de ticket
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        defaultValue={selectedTurn?.tag_number}
                        readOnly
                        style={{ border: "none" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#00154F",
                        color: "#FFFFFF",
                        padding: "20px",
                      }}
                    >
                      Quantity ID
                    </td>
                    <td colSpan="3">
                      <Form.Control
                        type="text"
                        style={{ border: "none" }}
                        {...register("quantityID", {
                          required: "Debes poner un Quantity ID",
                          pattern: {
                            value: /^\d{8,12}$/,
                            message:
                              "El Quantity ID debe tener entre 8 y 12 dígitos y no puede contener letras ni caracteres especiales",
                          },
                        })}
                      />
                      <Form.Control
                        type="hidden"
                        style={{ border: "none" }}
                        value={selectedTurn?.id}
                        {...register("id", {})}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#a3a3a3",
                        color: "#FFFFFF",
                        padding: "20px",
                      }}
                    >
                      Nombre del cliente
                    </td>
                    <td colSpan="3">
                      <Form.Control
                        type="text"
                        defaultValue={selectedTurn?.customer_name}
                        readOnly
                        style={{ border: "none" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#a3a3a3",
                        color: "#FFFFFF",
                        padding: "20px",
                      }}
                    >
                      No. de tarimas
                    </td>
                    <td colSpan="3">
                      <Form.Control
                        type="text"
                        defaultValue={selectedTurn?.number_pallets}
                        readOnly
                        style={{ border: "none" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#a3a3a3",
                        color: "#FFFFFF",
                        padding: "20px",
                      }}
                    >
                      Destino
                    </td>
                    <td colSpan="3">
                      <Form.Control
                        type="text"
                        defaultValue={selectedTurn?.destination}
                        readOnly
                        style={{ border: "none" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#a3a3a3",
                        color: "#FFFFFF",
                        padding: "20px",
                      }}
                    >
                      Fecha
                    </td>
                    <td colSpan="3">
                      <Form.Control
                        type="text"
                        defaultValue={selectedTurn.date.split(" ")[0]}
                        readOnly
                        style={{ border: "none" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#a3a3a3",
                        color: "#FFFFFF",
                        padding: "20px",
                      }}
                    >
                      Hora
                    </td>
                    <td colSpan="3">
                      <Form.Control
                        type="text"
                        defaultValue={selectedTurn.date.split(" ")[1]}
                        readOnly
                        style={{ border: "none" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#a3a3a3",
                        color: "#FFFFFF",
                        padding: "20px",
                      }}
                    >
                      Mercancia
                    </td>
                    <td colSpan="3">
                      <Form.Control
                        type="text"
                        defaultValue={selectedTurn?.content}
                        readOnly
                        style={{ border: "none" }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
              {errors.quantityID && (
                <span
                  style={{
                    color: "red",
                    fontSize: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {errors.quantityID.message}
                </span>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="submit"
                style={{
                  backgroundColor: "#035cf7",
                  border: "none",
                  width: "100%",
                  fontWeight: "bold",
                }}
              >
                Confirmar
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default Weigher;
