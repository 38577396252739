import { useForm } from "react-hook-form";
import "./setter-form.css";
import { FaAngleRight } from "react-icons/fa6";
import Swal from "sweetalert2";
import { addData, getData, formService, getBtnEmmit, setConfirmationModal, formResetService } from "./setter-form-service";
import { useEffect, useRef, useState } from "react";
import axios from "axios";

function SetterForm() {
  const [destinationArr, setDestinationArr] = useState([]);
  const [inputConsignerValue, setInputConsignerValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const [consigneeArr, setConsigneeArr] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid, isSubmitted },
  } = useForm({mode: 'onChange', shouldFocusError: true,});
  let formBtnClick = false;
  const inputConsignee = useRef(null);

  useEffect(() => {
    const subscription = formService.formSubmitSubject.subscribe(async () => {
      const isValidForm = await trigger();
      if (isValidForm && getBtnEmmit()) {
        handleSubmit(setterSubmit)();
      } else if(!isValidForm && getBtnEmmit()) {
        if(getData().length < 1) {
          handleSubmit(setterSubmit)();
        } else {
          setConfirmationModal(true);
          return;
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [handleSubmit, trigger]);

  useEffect(() => {
    getDestinations();
  },[]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (inputConsignerValue) {
        setDebouncedValue(inputConsignerValue);
      } else {
        setConsigneeArr([]);
      }
    }, 500);

    return () => clearTimeout(handler);
  }, [inputConsignerValue]);

  useEffect(() => {
    if (debouncedValue) {
      const fetchData = async () => {
        try {
          const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_HEAVY_LOAD}/setter/search?query=${debouncedValue}`;
  
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("heavy-load-token")}`
            }
          });
          if(response.data.data.results.length === 0) {
            setConsigneeArr([]);
          } else {
            setConsigneeArr(response.data.data.results);
          }
        } catch (error) {
          console.error(error);
        }
      }
      fetchData();
    }
  }, [debouncedValue]);

  useEffect(() => {
    const subscription = formResetService.onReset().subscribe(() => {
      reset();
    });

    return () => subscription.unsubscribe();
  }, [reset]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if(inputConsignee.current && !inputConsignee.current.contains(event.target)) {
        setConsigneeArr([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [])

  const handleOptionClick = (name) => {
    setValue("consigne_name", name);
    setConsigneeArr([]);
  }

  const setterSubmit = async (data) => {
    if(formBtnClick === false) {
      addData(data);
      setConfirmationModal(true);
      return;
    }
    Swal.fire({
      title: "¿Quieres agregar otro embarque?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then((result) => {
      if(result.isConfirmed) {
        addData(data);
        reset();
      }
    })
  };

  const getDestinations = async () => {
    try {
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_HEAVY_LOAD}/setter/destination`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("heavy-load-token")}`
        }
      });
      const filterDestinations = response.data.data.destination.filter(
        (destination) => !destination.name.startsWith("MEX")
      );
      setDestinationArr(filterDestinations);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <form className="setter-form" autoComplete="off" onSubmit={handleSubmit(setterSubmit)}>
        <h6>Embarque {getData().length + 1}</h6>
        <div className="heavy-form-group">
          <input
            id="customer_name"
            type="text"
            placeholder=" "
            className="formInput"
            {...register("customer_name", {
              required: true,
            })}
          />
          <label htmlFor="customer_name" className="formLabel">
            Nombre cliente <span className="text-alert">*</span>
          </label>
          {errors.customer_name?.type === 'required' && isSubmitted && (
            <p>Este campo es requerido</p>
          )}
        </div>

        <div className="heavy-form-group consignee-content" ref={inputConsignee}>
          <input
            id="consigne_name"
            type="text"
            placeholder=" "
            className="formInput"
            {...register("consigne_name", {
              required: true,
              onChange: (e) => setInputConsignerValue(e.target.value),
            })}
            onKeyDown={(e) => {
              if(e.key === "Tab") {
                setConsigneeArr([]);
              }
            }}
          />
          <label htmlFor="consigne_name" className="formLabel">
            Nombre consignatario <span className="text-alert">*</span>
          </label>
          <div className={`answer-consignee ${consigneeArr.length === 0 ? "" : "active"}`}>
            {consigneeArr.map((option, index) => (
              <p key={index} onClick={() => (handleOptionClick(option.name))}>{option.name}</p>
            ))}
          </div>
          {errors.consigne_name?.type === 'required' && isSubmitted && (
            <p>Este campo es requerido</p>
          )}
        </div>

        <div className="heavy-form-group">
          <input
            id="content"
            type="text"
            placeholder=" "
            className="formInput"
            {...register("content", {
              required: true,
            })}
          />
          <label htmlFor="content" className="formLabel">
            Contenido <span className="text-alert">*</span>
          </label>
          {errors.content?.type === 'required' && isSubmitted && (
            <p>Este campo es requerido</p>
          )}
        </div>

        <div className="heavy-form-group">
          <input
            id="number_pallets"
            type="text"
            placeholder=" "
            className="formInput"
            {...register("number_pallets", {
              required: true,
              pattern: {
                value: /^[0-9]*$/,
                message: "Solo se permiten números",
              },
            })}
          />
          <label htmlFor="number_pallets" className="formLabel">
            Cantidad de tarimas de este embarque <span className="text-alert">*</span>
          </label>
          {errors.number_pallets?.type === 'required' && isSubmitted && (
            <p>Este campo es requerido</p>
          )}
          {errors.number_pallets?.type === "pattern" && (
            <p>{errors.number_pallets?.message}</p>
          )}
        </div>

        <div className="two-inputs-contain">

          <div className="heavy-form-group">
            <input
              id="pieces"
              type="text"
              placeholder=" "
              className="formInput"
              {...register("pieces", {
                required: true,
                pattern: {
                  value: /^[0-9]*$/,
                  message: "Solo se permiten números",
                },
              })}
            />
            <label htmlFor="pieces" className="formLabel">
              Piezas totales <span className="text-alert">*</span>
            </label>
            {errors.pieces?.type === 'required' && isSubmitted && (
              <p>Este campo es requerido</p>
            )}
            {errors.pieces?.type === "pattern" && (
              <p>{errors.pieces?.message}</p>
            )}
          </div>

          <div className="heavy-form-group">
            <select 
              className="formInput"
              id="destination_id"
              {...register("destination_id", {
                validate: (value) => value !== "destino"
              })}
            >
              <option value="destino">Destino</option>
              {destinationArr.map((destination, index) => (
                <option key={index} value={destination.id}>{destination.name}</option>
              ))}
            </select>
            {errors.destination_id && isSubmitted && (
              <p>Este campo es requerido</p>
            )}
          </div>
        </div>

        <div className="btn-container">
          <button type="submit" onClick={() => {formBtnClick = true;}}>Siguiente Embarque <FaAngleRight /></button>
        </div>
      </form>
    </>
  )
};

export default SetterForm;