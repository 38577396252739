import "./inspector.css";
import amc_logo from "../../../../../assets/imgs/amc-domestico/logo_AMC.png";
import { useEffect, useState } from "react";
import axios from "axios";

function Inspector() {
  const [shipments, setShipments] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_HEAVY_LOAD}/guard`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("heavy-load-token")}`,
      }
    });
    setShipments(response.data.shipments);
  }

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Etiqueta emitida";
      case 2:
        return "Pesaje";
      case 3:
        return "Documentando";
      case 4:
        return "finalizado";
      default:
        return "";
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 1:
        return "validated";
      case 2:
        return "in-process";
      case 3:
        return "documenting";
      case 4:
        return "approved";
      default:
        return "";
    }
  };

  function formatHour(dateString) {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");

    const isoFormat = `${year}-${month}-${day}T${timePart}`;

    const date = new Date(isoFormat);
    if (isNaN(date.getTime())) {
      return "Fecha inválida";
    }

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  return (
    <>
      <div className="inspector-container">
        <div className="inspector-header">
          <img src={amc_logo} alt="Aeromexico" />
        </div>
        
        <div className="body-weighing">
        <h4>Inspección</h4>

        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th># Turno</th>
                <th># Embarque</th>
                <th>Hora emisión</th>
                <th># Guía</th>
                <th>Descripción</th>
                <th>Destino</th>
                <th>Piezas</th>
                <th>Pesaje</th>
              </tr>
            </thead>

            <tbody>
              {shipments.map((row, index) => (
                <tr key={index}>
                  <td>{row.turn_number ? row.turn_number : "-"}</td>
                  <td>{row.tag_number ? row.tag_number : "-"}</td>
                  <td>{row.created_at ? formatHour(row.created_at) : "-"}</td>
                  <td>{row.awb ? row.awb : "-"}</td>
                  <td>
                    {row.merchandise_description
                      ? row.merchandise_description
                      : "-"}
                  </td>
                  <td>{row.destination ? row.destination : "-"}</td>
                  <td>{row.pieces ? row.pieces : "-"}</td>
                  <td>
                    <span className={`status ${getStatusClass(row?.status)}`}>
                      {row.status ? getStatusText(row.status) : "-"}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      </div>
    </>
  )
}

export default Inspector;