import React, { useState } from "react";
import "./style.css";

import Header from "../components/heavy-header/heavy-header";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const Form = ({ register, errors }) => {
  return (
    <>
      <div className="amc-login-form-title">
        <h1>¡Bienvenido!</h1>
        <span>Ingresa tu usuario y contraseña</span>
      </div>
      <div className="amc-login-form-inputs">
        {/* Usuario */}
        <div className="formGroup">
          <input
            className="formInput"
            type="text"
            name="email"
            id="email"
            placeholder=""
            {...register("email", {
              required: "Este campo es requerido",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: "Formato de correo inválido",
              },
            })}
          />
          <label htmlFor="email" className="formLabel">
            Usuario
          </label>
          {errors.email && (
            <span className="alert-text">{errors.email.message}</span>
          )}
        </div>
        {/* Contraseña */}
        <div className="formGroup">
          <input
            className="formInput"
            type="password"
            autoComplete="current-password"
            name="password"
            id="password"
            placeholder=""
            {...register("password", {
              required: "Este campo es requerido",
            })}
          />
          <label htmlFor="password" className="formLabel">
            Password
          </label>
          {errors.password && (
            <span className="alert-text">{errors.password.message}</span>
          )}
        </div>
      </div>
      <div className="amc-login-buton">
        <button type="submit" className="amc-login-buton-next">
          {" "}
          Siguiente &#x276f;
        </button>
      </div>
    </>
  );
};

const Profile = ({
  permissos,
  profile,
  handleProfile,
  showError,
  navigateToProfile,
}) => {
  return (
    <>
      <div className="amc-login-profile">
        <div className="amc-login-profile-title">
          <h1>¡Bienvenido!</h1>
          <span>Selecciona el perfil que te corresponda</span>
        </div>
        <div className="amc-login-profile-type-profile">
          {/* Colocador */}
          {permissos.includes("19") && (
            <div className="profile-container">
              <input
                type="radio"
                id="colocador"
                name="profile"
                value="colocador"
                style={{ display: "none" }}
                onChange={handleProfile}
              />
              <label
                htmlFor="colocador"
                className="amc-login-profile-circle"
                style={
                  profile === "colocador" ? { backgroundColor: "#035cf7" } : {}
                }
              >
                <svg
                  width="54"
                  height="52"
                  viewBox="0 0 54 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="icon/colocador">
                    <path
                      id="icon/colocador_2"
                      d="M0.606445 52V11.5556L27.0004 0L53.3943 11.5556V52H37.558V28.8889H16.4428V52H0.606445ZM19.0822 52V46.2222H24.361V52H19.0822ZM24.361 43.3333V37.5556H29.6398V43.3333H24.361ZM29.6398 52V46.2222H34.9186V52H29.6398Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </label>
              <div className="amc-login-profile-label">
                <span>Colocador</span>
              </div>
            </div>
          )}

          {/* Pesador */}
          {permissos.includes("20") && (
            <div className="profile-container">
              <input
                type="radio"
                id="pesador"
                name="profile"
                value="pesador"
                style={{ display: "none" }}
                onChange={handleProfile}
              />
              <label
                htmlFor="pesador"
                className="amc-login-profile-circle"
                style={
                  profile === "pesador" ? { backgroundColor: "#035cf7" } : {}
                }
              >
                <svg
                  width="54"
                  height="52"
                  viewBox="0 0 54 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="icon/pesador">
                    <path
                      id="icon/pesador_2"
                      d="M27.0009 11.5556C27.936 11.5556 28.7204 11.2782 29.3541 10.7236C29.9878 10.1689 30.3035 9.48326 30.3013 8.66667C30.2991 7.85007 29.9823 7.16444 29.3508 6.60978C28.7193 6.05511 27.936 5.77778 27.0009 5.77778C26.0657 5.77778 25.2824 6.05511 24.6509 6.60978C24.0195 7.16444 23.7026 7.85007 23.7004 8.66667C23.6982 9.48326 24.0151 10.1699 24.6509 10.7264C25.2868 11.283 26.0701 11.5594 27.0009 11.5556ZM36.3246 11.5556H42.1004C43.7506 11.5556 45.1808 12.037 46.391 13C47.6011 13.963 48.3437 15.1426 48.6188 16.5389L53.3219 45.4278C53.5969 47.1611 53.0887 48.6903 51.7971 50.0153C50.5055 51.3404 48.841 52.0019 46.8035 52H7.19821C5.16293 52 3.49841 51.3384 2.20464 50.0153C0.910862 48.6922 0.402594 47.163 0.679832 45.4278L5.38296 16.5389C5.658 15.1426 6.4006 13.963 7.61076 13C8.82092 12.037 10.2511 11.5556 11.9013 11.5556H17.6771C17.5121 11.0741 17.3746 10.6051 17.2646 10.1487C17.1545 9.69222 17.0995 9.19822 17.0995 8.66667C17.0995 6.25926 18.0622 4.21296 19.9874 2.52778C21.9127 0.842593 24.2505 0 27.0009 0C29.7512 0 32.089 0.842593 34.0143 2.52778C35.9396 4.21296 36.9022 6.25926 36.9022 8.66667C36.9022 9.1963 36.8472 9.6903 36.7372 10.1487C36.6272 10.607 36.4896 11.076 36.3246 11.5556Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </label>
              <div className="amc-login-profile-label">
                <span>Pesador</span>
              </div>
            </div>
          )}

          {/* Documentador */}
          {permissos.includes("21") && (
            <div className="profile-container">
              <input
                type="radio"
                id="documentador"
                value="documentador"
                name="profile"
                style={{ display: "none" }}
                onChange={handleProfile}
              />
              <label
                htmlFor="documentador"
                className="amc-login-profile-circle"
                style={
                  profile === "documentador"
                    ? { backgroundColor: "#035cf7" }
                    : {}
                }
              >
                <svg
                  width="54"
                  height="52"
                  viewBox="0 0 54 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="icon/documentador">
                    <path
                      id="icon/documentador_2"
                      d="M10.336 52H43.6648C50.1619 52 53.3943 49.4533 53.3943 44.4085V22.3864H30.6721C26.6549 22.3864 24.7722 20.9066 24.7722 17.8021V0H10.336C3.87104 0 0.606445 2.57051 0.606445 7.61632V44.4085C0.606445 49.4771 3.87104 52 10.336 52ZM30.7671 19.0636H53.0489C52.8293 18.0691 51.9187 17.0984 50.4444 15.9104L33.1198 2.30456C31.6763 1.14038 30.3587 0.436696 29.0398 0.26595V17.7545C29.0398 18.6269 29.637 19.0636 30.7671 19.0636Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </label>
              <div className="amc-login-profile-label">
                <span>Documentador</span>
              </div>
            </div>
          )}

          {/* Pantalla turnos */}
          {permissos.includes("22") && (
            <div className="profile-container">
              <input
                type="radio"
                id="turnos"
                value="turnos"
                name="profile"
                style={{ display: "none" }}
                onChange={handleProfile}
              />
              <label
                htmlFor="turnos"
                className="amc-login-profile-circle"
                style={
                  profile === "turnos" ? { backgroundColor: "#035cf7" } : {}
                }
              >
                <svg
                  width="54"
                  height="52"
                  viewBox="0 0 54 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="icon/admin">
                    <path
                      id="icon/admin_2"
                      d="M27.0004 0L0.606445 9.45455V23.6364C0.606445 36.7545 11.8679 49.0218 27.0004 52C42.1329 49.0218 53.3943 36.7545 53.3943 23.6364V9.45455L27.0004 0ZM27.0004 9.21818C28.7405 9.21818 30.4415 9.63406 31.8883 10.4132C33.3351 11.1924 34.4628 12.2998 35.1287 13.5955C35.7946 14.8912 35.9688 16.317 35.6293 17.6925C35.2898 19.068 34.4519 20.3314 33.2215 21.3231C31.9911 22.3148 30.4234 22.9901 28.7168 23.2637C27.0101 23.5374 25.2412 23.3969 23.6335 22.8602C22.0259 22.3235 20.6519 21.4147 19.6851 20.2486C18.7184 19.0825 18.2024 17.7115 18.2024 16.3091C18.2024 14.4285 19.1293 12.6249 20.7793 11.2951C22.4292 9.96526 24.667 9.21818 27.0004 9.21818ZM27.0004 27.8909C32.8657 27.8909 44.5963 30.4673 44.5963 35.1709C42.6703 37.5112 40.0555 39.4309 36.9853 40.7589C33.9151 42.0869 30.4848 42.7819 27.0004 42.7819C23.516 42.7819 20.0857 42.0869 17.0155 40.7589C13.9453 39.4309 11.3305 37.5112 9.40443 35.1709C9.40443 30.4673 21.1351 27.8909 27.0004 27.8909Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </label>
              <div className="amc-login-profile-label">
                <span>Pantalla turnos principal</span>
              </div>
            </div>
          )}

          {/* Pantalla turnos sin sonido */}
          {permissos.includes("22") && (
            <div className="profile-container">
              <input
                type="radio"
                id="turnos_secundary"
                value="turnos_secundary"
                name="profile"
                style={{ display: "none" }}
                onChange={handleProfile}
              />
              <label
                htmlFor="turnos_secundary"
                className="amc-login-profile-circle"
                style={
                  profile === "turnos_secundary"
                    ? { backgroundColor: "#035cf7" }
                    : {}
                }
              >
                <svg
                  width="54"
                  height="52"
                  viewBox="0 0 54 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="icon/admin">
                    <path
                      id="icon/admin_2"
                      d="M27.0004 0L0.606445 9.45455V23.6364C0.606445 36.7545 11.8679 49.0218 27.0004 52C42.1329 49.0218 53.3943 36.7545 53.3943 23.6364V9.45455L27.0004 0ZM27.0004 9.21818C28.7405 9.21818 30.4415 9.63406 31.8883 10.4132C33.3351 11.1924 34.4628 12.2998 35.1287 13.5955C35.7946 14.8912 35.9688 16.317 35.6293 17.6925C35.2898 19.068 34.4519 20.3314 33.2215 21.3231C31.9911 22.3148 30.4234 22.9901 28.7168 23.2637C27.0101 23.5374 25.2412 23.3969 23.6335 22.8602C22.0259 22.3235 20.6519 21.4147 19.6851 20.2486C18.7184 19.0825 18.2024 17.7115 18.2024 16.3091C18.2024 14.4285 19.1293 12.6249 20.7793 11.2951C22.4292 9.96526 24.667 9.21818 27.0004 9.21818ZM27.0004 27.8909C32.8657 27.8909 44.5963 30.4673 44.5963 35.1709C42.6703 37.5112 40.0555 39.4309 36.9853 40.7589C33.9151 42.0869 30.4848 42.7819 27.0004 42.7819C23.516 42.7819 20.0857 42.0869 17.0155 40.7589C13.9453 39.4309 11.3305 37.5112 9.40443 35.1709C9.40443 30.4673 21.1351 27.8909 27.0004 27.8909Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </label>
              <div className="amc-login-profile-label">
                <span>Pantalla turnos Secundaria</span>
              </div>
            </div>
          )}

          {/* Inspector */}
          {permissos.includes("23") && (
            <div className="profile-container">
              <input
                type="radio"
                id="inspector"
                value="inspector"
                name="profile"
                style={{ display: "none" }}
                onChange={handleProfile}
              />
              <label
                htmlFor="inspector"
                className="amc-login-profile-circle"
                style={
                  profile === "inspector" ? { backgroundColor: "#035cf7" } : {}
                }
              >
                <svg
                  width="54"
                  height="52"
                  viewBox="0 0 54 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="icon/guardia">
                    <path
                      id="Vector"
                      d="M11.164 10.9474C11.164 4.89895 15.8885 0 21.7216 0C27.5547 0 32.2792 4.89895 32.2792 10.9474C32.2792 16.9958 27.5547 21.8947 21.7216 21.8947C15.8885 21.8947 11.164 16.9958 11.164 10.9474ZM27.0004 38.8632C27.0004 36.2358 28.3201 33.7726 30.1677 32.1305V31.4737C30.1677 30.4063 30.3524 29.3937 30.6427 28.4084C27.9242 27.7516 24.9153 27.3684 21.7216 27.3684C10.0555 27.3684 0.606445 32.2674 0.606445 38.3158V43.7895H27.0004V38.8632ZM53.3943 39.1368V48.7158C53.3943 50.3579 51.8107 52 49.9631 52H35.4464C33.8628 52 32.2792 50.3579 32.2792 48.4421V38.8632C32.2792 37.2211 33.8628 35.5789 35.4464 35.5789V31.4737C35.4464 27.6421 39.1416 24.6316 42.8368 24.6316C46.5319 24.6316 50.2271 27.6421 50.2271 31.4737V35.5789C51.8107 35.5789 53.3943 37.2211 53.3943 39.1368ZM46.7958 31.4737C46.7958 29.2842 44.9483 27.9158 42.8368 27.9158C40.7252 27.9158 38.8777 29.2842 38.8777 31.4737V35.5789H46.7958V31.4737Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </label>
              <div className="amc-login-profile-label">
                <span>Inspector</span>
              </div>
            </div>
          )}
        </div>
        {showError && (
          <span className="alert-text">Debe selecionar un perfil</span>
        )}

        <div className="amc-login-buton">
          <button
            type="button"
            className="amc-login-buton-next"
            onClick={navigateToProfile}
          >
            {" "}
            Iniciar Sesión &#x276f;
          </button>
        </div>
      </div>
    </>
  );
};

const LoginHeavyLoad = () => {
  const [step, setStep] = useState(1);
  const [permissions, setPermissions] = useState([]);
  const [profile, setProfile] = useState("");
  const [showError, setShowError] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const login = async (data) => {
    const body = { email: data.email, password: data.password };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_HEAVY_LOAD}/user/login`,
        body
      );
      if (res.data.code === 1) {
        const user = res.data.data;
        localStorage.setItem("heavy-load-user", user.name);
        localStorage.setItem("heavy-load-email", user.email);
        localStorage.setItem("heavy-load-token", user.token);
        localStorage.setItem("heavy-load-permissos", user.permissions);
        localStorage.setItem("heavy-load-kpi_id", user.kpi_id);
        if (user.counter_checkout.id !== null) {
          localStorage.setItem("heavy-counterId", user.counter_checkout.id);
        }
        setPermissions(user?.permissions);
        setStep(2);
      } else {
        throw new Error(res);
      }
    } catch (error) {
      console.error(error);
      if (
        error.response.data.code === -1 ||
        error.response.data.message === "Correo o contraseña incorrectos"
      ) {
        Swal.fire("Error", "Correo o contraseña incorrectos", "error");
      }
    }
  };

  const handleProfile = (e) => {
    setProfile(e.target.value);
  };

  const getProfileNumber = (profile) => {
    switch (profile) {
      case "colocador":
        return "1";
      case "pesador":
        return "2";
      case "documentador":
        return "3";
      case "turnos":
        return "4";
      case "turnos_secundary":
        return "4";
      case "inspector":
        return "5";
      default:
        return "";
    }
  };

  const navigateToProfile = async () => {
    if (profile) {
      try {
        const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_HEAVY_LOAD}/user/operation`;
        const body = {
          kpi_id: localStorage.getItem("heavy-load-kpi_id"),
          profile: getProfileNumber(profile),
        };
        const response = await axios.put(url, body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("heavy-load-token")}`,
          },
        });

        const profileRoutes = {
          colocador: "/amc-domestico/heavy-load/setter",
          pesador: "/amc-domestico/heavy-load/weigher",
          documentador: "/amc-domestico/heavy-load/documenter",
          turnos: "/amc-domestico/heavy-load/reception",
          turnos_secundary: "/amc-domestico/heavy-load/reception-nv",
          inspector: "/amc-domestico/heavy-load/inspector",
        };

        navigate(profileRoutes[profile], {
          state: { from: "/amc-domestico/heavy-load" },
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      setShowError(true);
    }
  };

  return (
    <>
      <div className="amc-login">
        <Header />
        <form onSubmit={handleSubmit(login)} autoComplete="off">
          <div className="amc-login-form">
            {step === 1 ? (
              <Form register={register} errors={errors} />
            ) : (
              <Profile
                permissos={permissions}
                profile={profile}
                handleProfile={handleProfile}
                showError={showError}
                navigateToProfile={navigateToProfile}
              />
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default LoginHeavyLoad;
