import { useForm } from "react-hook-form";
import "./clients-form.css";
import { FaAngleRight } from "react-icons/fa6";
import Swal from "sweetalert2";
import {
  addData,
  formResetService,
  formService,
  getBtnEmmit,
  getData,
  setConfirmationModal,
} from "./clients-form-service";
import { useEffect, useRef, useState } from "react";
import axios from "axios";

function ClientsForm() {
  const [destinationArr, setDestinationArr] = useState([]);
  const [inputConsignerValue, setInputConsignerValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const [consigneeArr, setConsigneeArr] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ mode: "onChange", shouldFocusError: true });
  let formBtnClick = false;
  const inputConsignee = useRef(null);

  useEffect(() => {
    const subscription = formService.formSubmitSubject.subscribe(async () => {
      const isValidForm = await trigger();
      if (isValidForm && getBtnEmmit()) {
        handleSubmit(clientsSubmit)();
      } else if (!isValidForm && getBtnEmmit()) {
        if (getData().length < 1) {
          handleSubmit(clientsSubmit)();
        } else {
          setConfirmationModal(true);
          return;
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, trigger]);

  useEffect(() => {
    getDestinations();
  }, []);

  useEffect(() => {
    const subscription = formResetService.onReset().subscribe(() => {
      reset();
    });
    return () => subscription.unsubscribe();
  }, [reset]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (inputConsignerValue) {
        setDebouncedValue(inputConsignerValue);
      } else {
        setConsigneeArr([]);
      }
    }, 500);

    return () => clearTimeout(handler);
  }, [inputConsignerValue]);

  useEffect(() => {
    if (debouncedValue) {
      const fetchData = async () => {
        try {
          const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/shipment/search?query=${debouncedValue}`;
  
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("light-load-token")}`
            }
          });
          if(response.data.data.results.length === 0) {
            setConsigneeArr([]);
          } else {
            setConsigneeArr(response.data.data.results);
          }
        } catch (error) {
          console.error(error);
        }
      }
      fetchData();
    }
  }, [debouncedValue]);

  const clientsSubmit = async (data) => {
    if (formBtnClick === false) {
      addData(data);
      setConfirmationModal(true);
      return;
    }
    Swal.fire({
      title: "¿Quieres agregar otro embarque?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        addData(data);
        reset();
      }
    });
  };

  const getDestinations = async () => {
    try {
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/shipment/destination`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
        },
      });
      const filterDestinations = response.data.data.destination.filter(
        (destination) => !destination.name.startsWith("MEX")
      );
      setDestinationArr(filterDestinations);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOptionClick = (name) => {
    setValue("name", name);
    setConsigneeArr([]);
  }

  return (
    <>
      <form
        className="clients-form"
        autoComplete="off"
        onSubmit={handleSubmit(clientsSubmit)}
      >
        <h6>Embarque {getData().length + 1}</h6>
        <div className="light-form-group consignee-content" ref={inputConsignee}>
          <input
            id="name"
            type="text"
            placeholder=" "
            className="formInput"
            {...register("name", {
              required: true,
              onChange: (e) => setInputConsignerValue(e.target.value),
            })}
            onKeyDown={(e) => {
              if(e.key === "Tab") {
                setConsigneeArr([]);
              }
            }}
          />
          <label htmlFor="name" className="formLabel">
            Nombre consignatario <span className="text-alert">*</span>
          </label>
          <div className={`answer-consignee ${consigneeArr.length === 0 ? "" : "active"}`}>
            {consigneeArr.map((option, index) => (
              <p key={index} onClick={() => (handleOptionClick(option.name))}>{option.name}</p>
            ))}
          </div>
          {errors.name?.type === "required" && isSubmitted && (
            <p>Este campo es requerido</p>
          )}
        </div>
        <div className="light-form-group">
          <input
            id="merchandise_description"
            type="text"
            placeholder=" "
            className="formInput"
            {...register("merchandise_description")}
          />
          <label htmlFor="merchandise_description" className="formLabel">
            Descripción de mercancía
          </label>
        </div>
        <div className="two-inputs-contain">
          <div className="light-form-group">
            <input
              id="pieces"
              type="text"
              placeholder=" "
              className="formInput"
              {...register("pieces", {
                required: "Este campo es requerido",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Solo se permiten números",
                },
              })}
            />
            <label htmlFor="pieces" className="formLabel">
              No. de piezas <span className="text-alert">*</span>
            </label>
            {errors.pieces && isSubmitted && <p>{errors.pieces.message}</p>}
          </div>
          <div className="light-form-group">
            <select
              className="formInput"
              id="destination_id"
              {...register("destination_id", {
                validate: (value) => value !== "destino",
              })}
            >
              <option value="destino">Destino</option>
              {destinationArr.map((destination, index) => (
                <option key={index} value={destination.id}>
                  {destination.name}
                </option>
              ))}
            </select>
            {errors.destination_id && isSubmitted && (
              <p>Este campo es requerido</p>
            )}
          </div>
        </div>
        <div className="light-form-group-check">
          <input
            className="formInput"
            id="check_animal"
            type="checkbox"
            {...register("check_animal")}
          />
          <label htmlFor="check_animal" className="formLabel">
            Animales vivos
          </label>
        </div>
        <div className="btn-container">
          <button
            type="submit"
            onClick={() => {
              formBtnClick = true;
            }}
          >
            Siguiente Embarque <FaAngleRight />
          </button>
        </div>
      </form>
    </>
  );
}

export default ClientsForm;
