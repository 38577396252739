/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./style.css";

import amc_logo from "../../../../../assets/imgs/amc-domestico/logo_AMC.png";
import axios from "axios";

const HeavyLoadReceptionSecunday = () => {
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [turns, setTurns] = useState([]);

  const fetchAllShifts = async () => {
    try {
      // Ejecutar todas las solicitudes en paralelo
      const [currentRes, nextRes, lastRes] = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_HEAVY_LOAD}/supervisor/turn_in_progress`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "heavy-load-token"
              )}`,
            },
          }
        ),
        axios.get(
          `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_HEAVY_LOAD}/supervisor/turns_next`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "heavy-load-token"
              )}`,
            },
          }
        ),
        axios.get(
          `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_HEAVY_LOAD}/supervisor/turns_in_progress`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "heavy-load-token"
              )}`,
            },
          }
        ),
      ]);

      // Procesar las respuestas
      let currentTurn = [];
      let nextTurns = [];
      let lastTurns = [];

      // Procesar turno actual
      if (currentRes.data.code === 1) {
        currentTurn = [{ ...currentRes.data.turn, type: "current" }];
      }

      // Procesar turnos siguientes
      nextTurns = (nextRes.data?.turns_next || []).map((turn) => ({
        ...turn,
        type: "last",
      }));

      // Procesar turnos pasados
      lastTurns = (lastRes.data?.data?.turns || []).map((turn) => ({
        ...turn,
        type: "next",
      }));

      // Ordenar por fecha
      nextTurns.sort((a, b) => new Date(a.date) - new Date(b.date));
      lastTurns.sort((a, b) => new Date(b.date) - new Date(a.date));

      // Seleccionar los primeros 5 de 'next'
      const selectedNext = nextTurns.slice(0, 5);

      // Seleccionar los primeros 5 de 'last'
      const selectedLast = lastTurns.slice(0, 5);

      // Combinar en el orden solicitado
      const orderedShifts = [
        ...selectedLast,
        ...(currentTurn.length > 0 ? currentTurn : []),
        ...selectedNext,
      ];

      // Asignar al estado
      setTurns(orderedShifts);
    } catch (error) {
      console.error("Error fetching shifts:", error);
    }
  };

  function formatHour(dateString) {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");

    const isoFormat = `${year}-${month}-${day}T${timePart}`;

    const date = new Date(isoFormat);
    if (isNaN(date.getTime())) {
      return "Fecha inválida";
    }

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  useEffect(() => {
    fetchAllShifts();

    const interval = setInterval(fetchAllShifts, 15000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    function getFormattedDate() {
      const days = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ];
      const months = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ];

      const now = new Date();
      const dayName = days[now.getDay()];
      const day = now.getDate();
      const monthName = months[now.getMonth()];
      const year = now.getFullYear();

      return `${dayName}, ${day} de ${monthName} ${year}`;
    }

    function getFormattedTime() {
      const now = new Date();
      let hours = now.getHours(); // Mantén las horas tal cual están en formato de 24 horas
      const minutes = now.getMinutes().toString().padStart(2, "0");

      // Devuelve el tiempo en formato 24 horas
      return `${hours}:${minutes}`;
    }

    const interval = setInterval(() => {
      setCurrentDate(getFormattedDate());
      setCurrentTime(getFormattedTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="reception-container">
        <div className="reception-banner">
          <div className="banner-header">
            <div className="header-image">
              <img src={amc_logo} alt="amc-logo" />
            </div>
            <div className="header-hours">
              <span>{currentDate}</span>
              <span>{currentTime}</span>
            </div>
          </div>
          <div className="banner-body">
            <video
              src="https://dyn-pwc-ac.amlab7.com/pantalla-turnos-video-v2.mp4"
              autoPlay
              loop
              muted
              playsInline
              className="banner-video"
            />
          </div>
        </div>
        <div className="reception-turns">
          <div className="turns-body">
            <div className="next-shifts">
              <div className="tabla">
                <div className="tabla-header">
                  <div className="header-cell">Turno</div>
                  <div className="header-cell">Mostrador</div>
                  <div className="header-cell">Hora</div>
                </div>
                {turns.length === 0 ? (
                  <p
                    style={{
                      marginTop: "10vh",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    No hay turnos por mostrar
                  </p>
                ) : (
                  <>
                    {turns.slice(0, 11).map((item, index) => {
                      const checkoutName = item.counter_checkout_name
                        ? item.counter_checkout_name.replace("Mostrador ", "")
                        : "-";

                      return (
                        <div className="tabla-row" key={index}>
                          <div
                            className={`cell ${
                              item.type === "current" ? "current-turn" : ""
                            } ${item.type === "next" ? "next-turn" : ""} ${
                              item.type === "last" ? "last-turn" : ""
                            }`}
                          >
                            {item.turn_number}
                          </div>
                          <div
                            className={`cell ${
                              item.type === "current" ? "current-turn" : ""
                            } ${item.type === "next" ? "next-turn" : ""} ${
                              item.type === "last" ? "last-turn" : ""
                            }`}
                          >
                            {checkoutName}
                          </div>
                          <div
                            className={`cell ${
                              item.type === "current" ? "current-turn" : ""
                            } ${item.type === "next" ? "next-turn" : ""} ${
                              item.type === "last" ? "last-turn" : ""
                            }`}
                          >
                            {formatHour(item.date) === "Fecha inválida"
                              ? "-"
                              : formatHour(item.date)}
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
            <div className="turns-body-messages">
              <h1>Orden de turno conforme a inspección</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeavyLoadReceptionSecunday;
